function getTotal(params) {
  return params.row.quantity * params.row.unitPrice;
}

let test_headers = [
	{
		field: "type",
		headerName: "Service",
		flex: 1,
	},
	{
		field: "location",
		headerName: "Location",
		flex: 1,
	},
	{
		field: "speed",
		headerName: "Speed",
		flex: 1,
	},
	{
		field: "quantity",
		headerName: "Quantity",
		flex: 1,
		editable: true,
	},
	{
		field: "unitPrice",
		headerName: "Unit Price",
		flex: 1,
		editable: true,
	},
	{
		field: "total",
		headerName: "Total",
		flex: 1,
		valueGetter: getTotal,
	},
];

export default test_headers;
