import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { DesktopDatePicker } from '@mui/lab';

export default function BasicDatePicker(props) {
  
  const [value, setValue] = React.useState(null);

  let handleChange = (date) => {
    console.log({date})
    setValue(date);
    props.handleSetDate(date);
  };

  return (
    <div className='m-2'>
      <DesktopDatePicker
        label={props.label}
        value={value}
        inputFormat="MM/dd/yyyy"
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        renderInput={(params) => <TextField size="small" type="date" {...params} />}
      />
    </div>
  );
}