import { DataGrid } from '@mui/x-data-grid';
import test_listing_headers from '../lib/TestListingHeaders';
import { GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

let TestCountsTableContainer = (props) => {

	let headers = test_listing_headers;
	
	let [displayData, setDisplayData] = useState([]);

	useEffect(() => {
		setDisplayData(props.data);
	}, [props.data]);
	
	console.log(displayData);

	return(
			<React.Fragment>
			<div className='col-12 col-xxl-8 p-2 mb-5'>
				<DataGrid
					initialState={{
						pagination: {
							pageSize: 10,
						},
					}}
					components={{ Toolbar: GridToolbar }}
					disableSelectionOnClick
					rows={displayData}
					columns={headers}
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					autoHeight
				/>
			</div>
		</React.Fragment>
	);

}

export default TestCountsTableContainer;
 