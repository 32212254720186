import axios from 'axios';
import { useQuery } from 'react-query';

let BillManager = (props) => {

	const { isLoading, isError, data, error } = useQuery('bills', () => {
		return axios.get('/api/bills');
	});

	if (isLoading) { return <p>Loading...</p>; }

	if (isError) { return <p>Error: {error.message}</p>; }

	if(data.keys.length === 0) { return <p>No bills found</p>; }

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-md-12'>
					<h1>Bills</h1>git 
				</div>
			</div>
		</div>
	);
}
export default BillManager;