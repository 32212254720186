import faker from '@faker-js/faker';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';

let columns = [{field: "name", headerName: 'Group Code Name', flex: 1}];

let GroupCodeSelector = (props) => {

	let { isLoading, isError, data, error } = useQuery(["codes", props.portals], () => {
		return axios.post('/billing/getcodes', props.portals, {headers: {'Content-Type': 'application/json'}});
	});

	if (isLoading) { return <h2>Loading...</h2>; }

	if (isError) { return <p>Error: {error.message}</p>; }

	if(data.length === 0) { return <h2>No codes found.</h2>; }
	
	return(
		<div className='col-md-12 col-lg-6 col-xxl-4 p-2'>
			<DataGrid
				rows={data.data}
				columns={columns}
				pageSize={10}
				pageSizeOptions={[5, 10, 20, 50, 100]}
				checkboxSelection
				autoHeight
				onSelectionModelChange={(model) => {props.handleSetCodes(model);}}
			/>
		</div>
	);

}

export default GroupCodeSelector;