import { DataGrid } from '@mui/x-data-grid';
import test_headers from '../lib/TestHeaders';
import TestData from '../lib/TestData';
import { Datagrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useQuery } from 'react-query';
import TestGranularTableContainer from './TestGranularTableContainer';

let TestDisplay = (props) => {

	let req = {
		ids: props.codes,
		start: props.start,
		end: props.end,
		timezoneOffset: props.start.getTimezoneOffset(),
	}

	let { isLoading, isError, data, error } = useQuery(["granular", ...props.codes, props.start, props.end], () => {
		return axios.post('/billing/testlisting', JSON.stringify(req), {headers: {'Content-Type': 'application/json'}});
	});

	if (isLoading) { return <h2>Loading...</h2>; }

	if (isError) { return <p>Error: {error.message}</p>; }

	if(data.data === null) { return <h2>No codes found.</h2>; }
	
	console.log("granular", data.data);
	
	return(	
		<React.Fragment>
			<TestGranularTableContainer data={data.data} />
		</React.Fragment>
	);
	
}

export default TestDisplay;