import { DataGrid } from '@mui/x-data-grid';
import test_headers from '../lib/TestHeaders';
import TestData from '../lib/TestData';
import { Datagrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useQuery } from 'react-query';
import TestCountsTableContainer from './TestCountsTableContainer';


let TestDisplay = (props) => {

	let req = {
		ids: props.codes,
		start: props.start.toJSON(),
		end: props.end.toJSON(),
		timezoneOffset: props.start.getTimezoneOffset(),
		groupByLocation: props.groupByLocations,
		groupBySpeed: props.groupBySpeed,
	}

	console.log("request", req);

	let { isLoading, isError, data, error } = useQuery(['counts', ...props.codes, props.start, props.end, props.groupByLocations, props.groupBySpeed], () => {
		return axios.post('/billing/testcounts', JSON.stringify(req), {headers: {'Content-Type': 'application/json'}});
	});

	if (isLoading) { return <h2>Loading...</h2>; }

	if (isError) { return <p>Error: {error.message}</p>; }

	if(data.data === null) { return <h2>No codes found.</h2>; }

	console.log("counts", data.data);
	
	return(	
		<React.Fragment>
			<TestCountsTableContainer data={data.data} />
		</React.Fragment>
	);
	
}

export default TestDisplay;