import { useState } from 'react';
import Dashboard from './components/Dashboard';
import CreateBill from './components/CreateBill';
import BillManager from './components/BillManager';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Button } from '@mui/material';

function App() {

  let pages = [ "Dashboard", "Create Bill", "Manage Bills"];
  let [page, setPage] = useState(1);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className="d-flex justify-content-start">
          {/* <Button className="m-2" variant="contained" onClick={() => setPage(0)}>{pages[0]}</Button> */}
          {/* <Button className="m-2" variant="contained" onClick={() => setPage(1)}>{pages[1]}</Button> */}
          {/* <Button className="m-2" variant="contained" onClick={() => setPage(2)}>{pages[2]}</Button> */}
        </div>
        {page === 0 && <Dashboard />}
        {page === 1 && <CreateBill />}
        {page === 2 && <BillManager />}
      </div>
    </div>
  );
}

export default App;
