import { DataGrid } from '@mui/x-data-grid';
import test_headers from '../lib/TestHeaders';
import { GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';



let TestCountsTableContainer = (props) => {

	let queryClient = useQueryClient();

	let headers = test_headers;
	
	let [displayData, setDisplayData] = useState([]);
	let [itemType, setItemType] = useState('');
	let [itemQuantity, setItemQuantity] = useState(0);
	let [itemPrice, setItemPrice] = useState(0);
	let [location, setLocation] = useState('');
	let [speed, setSpeed] = useState('');

	useEffect(() => {
		setDisplayData(props.data);
	}, [props.data]);

	let addLineItem = () => {
		if(itemQuantity === 0 || itemPrice === 0){
			toast.error('Please enter a quantity and price.');
			return;
		}
		let item = {
			id: displayData.length,
			type: itemType,
			location: location,
			speed: speed,
			quantity: itemQuantity,
			unitPrice: itemPrice,
		}
		setDisplayData(prevdata => [...prevdata, item]);
		setItemPrice(0);
		setItemQuantity(0);
		setItemType('');
	}

	let handleRowEdit = (params) => {
		let newData = [...displayData];
		newData[params.id][params.field] = params.value;
		setDisplayData(newData);
	}

	let calculateGrandTotal = () => {
		let total = 0;
		displayData.forEach(item => {
			total += item.quantity * item.unitPrice;
		});
		return total;
	}

	let resetData = () => {
		queryClient.invalidateQueries('counts');
		setDisplayData([...props.data]);
	}

	return(
			<React.Fragment>
			<div className='col-12 col-xxl-8 p-2 mb-5'>
				<div className='d-flex p-2 justify-content-between align-items-center'>
					<div className='d-flex align-items-center'>
						<TextField className="m-2" label="Type" variant="standard" value={itemType} onChange={(e) => setItemType(e.target.value) } />
						<TextField className="m-2" label="Quantity" variant="standard" value={itemQuantity} onChange={(e) => setItemQuantity(e.target.value) } />
						<TextField className="m-2" label="Unit Price" variant="standard" value={itemPrice} onChange={(e) => setItemPrice(e.target.value) } />
						<TextField className="m-2" label="Location" variant="standard" value={location} onChange={(e) => setLocation(e.target.value) } />
						<TextField className="m-2" label="Speed" variant="standard" value={speed} onChange={(e) => setSpeed(e.target.value) } />
						
						<Button className='m-1' onClick={addLineItem} variant="contained">Add</Button>
						<Button className='m-1' onClick={resetData} color="warning" variant="contained">Reset</Button>
					</div>
					<h4 className='m-2 justify-self-end'>Grand Total: {calculateGrandTotal()}</h4>
				</div>
				<DataGrid
					initialState={{
						pagination: {
							pageSize: 10,
						},
					}}
					components={{ Toolbar: GridToolbar }}
					disableSelectionOnClick
					rows={displayData}
					columns={headers}
					onCellEditCommit={(params) => handleRowEdit(params)}
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					autoHeight
				/>
			</div>
		</React.Fragment>
	);

}

export default TestCountsTableContainer;
