import React, { useState } from 'react';
import BasicDatePicker from './BasicDatePicker';
import GroupCodeSelector from './GroupCodeSelector';
import PortalListing from './PortalListing';
import { toast } from 'react-toastify';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import TestCountsDisplay from './TestCountsDisplay';
import TestGranularDisplay from './TestGranularDisplay';


let CreateBill = (props) => {

	let [ portalIds, setPortalIds ] = useState([]);
	let [ codeIds, setCodeIds ] = useState([]);
	let [ startDate, setStartDate ] = useState(null);
	let [ endDate, setEndDate ] = useState(null);
	let [ showCodes, setShowCodes ] = useState(false);
	let [ showTest, setShowTest ] = useState(false);
	let [ groupByLocation, setGroupByLocation ] = useState(false);
	let [ groupBySpeed, setGroupBySpeed ] = useState(false);

	let handleSetPortalIds = (ids) => {
		setPortalIds(ids);
	}

	let handleSetStartDate = (date) => {
		setStartDate(date);
	}

	let handleSetEndDate = (date) => {
		setEndDate(date);
	}

	let handleSetCodeIds = (ids) => {
		setCodeIds(ids);
	}

	let handleSetShowCodes = () => {
		if(startDate === null || endDate === null || portalIds.length === 0) {
			toast.error('Please select one or more portals and both a start and end date.');
		}else{
			setShowCodes(!showCodes);
		}
	}

	let handleShowTests = () => {
		if(codeIds.length === 0) {
			toast.error('Please select one or more codes.');
		}else{
			setShowTest(!showTest);
		}
	}

	let renderTestDisplays = () => {
		return(
			<React.Fragment>
				<TestCountsDisplay codes={codeIds} start={startDate} end={endDate} groupByLocations={groupByLocation} groupBySpeed={groupBySpeed}/>
				<TestGranularDisplay codes={codeIds} start={startDate} end={endDate} />
			</React.Fragment>
		);
	}

	let renderTestControls = () => {
		return(
			<React.Fragment>
				<FormControlLabel className='m-1' label='Group by Location' control={<Checkbox />} value={groupByLocation} onChange={() => { setGroupByLocation(!groupByLocation)}}/>
				<FormControlLabel className='m-1' label='Group by Speed' control={<Checkbox />} value={groupBySpeed} onChange={() => { setGroupBySpeed(!groupBySpeed)}}/>
				<Button className='m-1' onClick={handleShowTests}>Get Tests</Button>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<div className='row'>
				<h1>Create Billing Report</h1>
			</div>
			<div className='d-inline-flex justify-content-start align-content-center'>
				<BasicDatePicker handleSetDate={handleSetStartDate} label='Start Date' />
				<BasicDatePicker handleSetDate={handleSetEndDate} label='End Date' />
				<Button onClick={handleSetShowCodes}>Get Codes</Button>
				{(codeIds.length > 0) ? renderTestControls() : null}
			</div>
			<div className='row justify-content-start align-content-center'>
				<PortalListing setPortalIds={handleSetPortalIds} />
					{(showCodes) ? <GroupCodeSelector portals={portalIds} handleSetCodes={handleSetCodeIds} /> : null}
			</div>
			<div className='row justify-content-start align-content-center'>
				{(showTest) ? renderTestDisplays() : null}
			</div>
		</React.Fragment>
	);
}

export default CreateBill;