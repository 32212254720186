import { useQuery } from 'react-query';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';

let PortalListing = (props) => {

	const { isLoading, isError, data, error } = useQuery('portals', () => {
		return axios.get('/billing/getportals');
	});

	if (isLoading) { return <p>Loading...</p>; }

	if (isError) { return <p>Error: {error.message}</p>; }

	if(data.length === 0) { return <p>No portals found</p>; }
	
	let columns = [{field: "name", headerName: 'Portal Name', flex: 1 }];

	return(	
		<div className='col-md-12 col-xl-6 col-xxl-4 p-2'>
			<DataGrid
				rows={data.data}
				columns={columns}
				pageSize={10}
				pageSizeOptions={[5, 10, 20, 50, 100]}
				autoHeight
				onSelectionModelChange={(model) => {props.setPortalIds(model);}}
			/>
		</div>
	);

}

export default PortalListing;