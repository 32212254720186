function getTotal(params) {
  return params.row.quantity * params.row.unitPrice;
}

let test_listing_headers = [
	{
		field: "firstName",
		headerName: "First Name",
		flex: 1,
	},
	{
		field: "lastName",
		headerName: "Last Name",
		flex: 1,
	},
	{
		field: "type",
		headerName: "Test Type",
		flex: 1,
	},
	{
		field: "location",
		headerName: "Location",
		flex: 1,
	},
	{
		field: "speed",
		headerName: "Speed",
		flex: 1,
	},
	{
		field: "date",
		headerName: "Test Date",
		flex: 1,
	},
];

export default test_listing_headers;
