let Dashboard = (props) => {
	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-md-12'>
					<h1>Dashboard</h1>
				</div>
			</div>
		</div>
	);
}
export default Dashboard;